























import { Component, Vue } from "vue-property-decorator";

import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import MainSlogan from "@/components/common/MainSlogan.vue";
import WordOnPic from "@/components/common/WordOnPic.vue";
import AssignCard from "@/components/common/AssignCard.vue";

@Component({
  components: {
    Layout,
    MainSlogan,
    WordOnPic,
    AssignCard,
  },
})
export default class ZCCityChargestation extends Vue {}
